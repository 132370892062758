import logo from './logo.svg';
import './App.css';
import React from 'react';
import TalkToDrago from './pages/TalkToDrago';
import { Paper, Typography, Link } from '@mui/material'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


function App() {
  return (
    <div className="App" style={{ background: 'linear-gradient(180deg, #f5f5f5 50%, #ffffff 50%)' }}>
      <Typography variant="h4" style={{paddingTop: "20px", paddingBottom: "20px"}}><b>Talk to Loved Ones</b></Typography>
      <Paper className="paper" elevation={4} style={{marginBottom: "20px"}}>
        <TalkToDrago />
      </Paper>
      <Typography>
        <p style={{paddingLeft: "10px", paddingRight: "10px"}}>Let me know what you think at <Link href="mailto:hello@swyvel.io" underline="hover">hello@swyvel.io</Link> or <Link href="https://linkedin.com/in/dragodimitrov" underline="hover">LinkedIn</Link>.</p>
        <p style={{paddingLeft: "10px", paddingRight: "10px"}}>Learn more at <Link href="http://drago.life" underline="hover">Drago.Life</Link></p>
      </Typography>

    </div>
  );
}

export default App;
