import React from 'react'
import { useState } from 'react';
import imageDrago from '../assets/Drago_Headshot.png'
import imageNanny from '../assets/Nanny_Headshot.png'
import imageBumpa from '../assets/Bumpa_Headshot.png'
import imageDefault from '../assets/Default_Image.jpeg'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import axios from 'axios'
import './TalkToDrago.css';
import { BASE_URL} from '../utils/constants';


// const BASE_URL = 'https://3000-ddimitrov77-talktodrago-572sds5iunk.ws-us89b.gitpod.io'

const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'ClientTimeZone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    // paramsSerializer: params => queryString.stringify(params),
  });
  
axiosClient.interceptors.response.use(async (response) => {
  if (response.data) {
    return response.data;
  }

  return response;
}, (error) => {
  // Handle errors
  if (error.response) {
    throw error.response;
  }

  return error;
});


export default function TalkToDrago() {
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const [audioSource, setAudioSource] = useState(null);




    const handleAudioEnded = () => {
      setIsSpeaking(false);
      setShowAudioPlayer(false);
    }    
  
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(`Name: ${name}\nPrompt: ${prompt}`);
      console.log(process.env.REACT_APP_API_BASE_URL)
      // Replace the console.log statement with your desired form submission logic
      formSubmit(event.target)
    }

    const getGptResponse = async (input) => {
      try {
      console.log('input',input)

      const response = await axios.post(`${BASE_URL}/gpt-api`, {
          prompt: input[0],
          speaker: input[1],
      });
      console.log(response.data)
      return response.data;
      } catch (error) {
      console.error(error);
      return null;
      }
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const getVoice = (output) => {
      return new Promise((resolve, reject) => {
        axios.post(`${BASE_URL}/voice-api`, {
          prompt: output[0],
          speaker: output[1]
        }, {
          responseType: 'blob'
        }).then(response => {
          const binaryData = response.data
          const blob = new Blob([binaryData], { type: 'audio/mpeg' });
          // const audioURL = URL.createObjectURL(blob);
          const audioObjectURL = URL.createObjectURL(blob);
          setAudioSource(audioObjectURL);

          const audioElement = document.createElement('audio');
          audioElement.setAttribute('controls', 'true');
          // if (isSafari) {
            // audioElement.setAttribute('controls', 'true');
            // You might also display a message to Safari users asking them to click to play.
          // }

          setShowAudioPlayer(true);

          
          // document.body.appendChild(audioElement);  // Optionally, append this to a specific div or container in your DOM

          // const sourceElement = document.createElement('source');
          // audioElement.appendChild(sourceElement);
          // sourceElement.src = audioObjectURL;
          // sourceElement.type = 'audio/mp3';

          // // const audio = new Audio(audioURL);
          // // audio.addEventListener('ended', () => {
          // //   handleAudioEnded();
          // //   // console.log('audio ended');
          // // });

          
          // audioElement.addEventListener('ended', () => {
          //   handleAudioEnded();
          // });
          setIsSpeaking(true);
          
          audioElement.play(); 

          // audio.play(); 
          // console.log('audio played')

          // resolve(audio);
          resolve(audioElement);
        }).catch(error => {
          console.log(error);
          setLoading(false);
          reject(error);
        });
      });
    }


    const formSubmit = async (formData) =>{
      var data = new FormData(formData);
      try {
        setLoading(true)
        const url = `${BASE_URL}/api/v1/submissions`;
        console.log(url)
        console.log('the form data:', data)

        const response = await axiosClient.post(url, data);
        const updatedData = response
        console.log('response', response)

        const payload = [ prompt, selectedOption ]
        const gptResponse = await getGptResponse(payload)
        console.log(gptResponse)
        const gpt_response = gptResponse.choices[0].message.content
        updatedData.gpt_response = gpt_response
        const updateUrl = `${BASE_URL}/api/v1/submissions/${response.id}`
        const updatedResponse = await axiosClient.patch(updateUrl, updatedData);
        // console.log('updatedResponse', updatedResponse)
        const voicePayload = [gpt_response, selectedOption]
        await getVoice(voicePayload)
        setLoading(false)

      } catch(err) {
          console.log(err);
      }
    }

    const getImageSrc = () => {
      switch (selectedOption) {
        case 'Nanny':
          return imageNanny;
        case 'Bumpa':
          return imageBumpa;
        case 'Drago':
          return imageDrago;
        default:
          return imageDefault; // return a default image if none of the conditions match
      }
    };
  
  
    return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
        <div className="horizontalContainer" style={{display: 'flex', alignItmes:'center', justifyContent: 'center', paddingTop:"15px"}}>
            <div className={`${isSpeaking? 'imageContainer-active' : 'imageContainer'}`} style={{ maxWidth: '300px', maxHeight: '500px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src={getImageSrc()} alt={selectedOption} style={{ width: '100%', height: '100%', display: 'block'}} />
            </div>
        </div>
      <form onSubmit={handleSubmit}
      autoComplete="off">
        
        <div>
          <div>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
                <label htmlFor="option"><b>Talk to</b></label>
            </div>
            <FormControl variant="outlined" required style={{minWidth: '190px', marginTop: '5px'}}>
                <InputLabel id="option-label">Loved One</InputLabel>
                <Select
                    labelId="option-label"
                    id="option"
                    value={selectedOption}
                    onChange={(event) => setSelectedOption(event.target.value)}
                    name="submission[option]"
                    label="Option"
                >
                    <MenuItem value={"Nanny"}>Nanny</MenuItem>
                    <MenuItem value={"Bumpa"}>Bumpa</MenuItem>
                    <MenuItem value={"Drago"}>Drago</MenuItem>
                </Select>
            </FormControl>
        </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px'}}>
            <label htmlFor="name"><b>Your Name</b></label>
          </div>
          <TextField
            id="name"
            label="Your Name"
            value={name}
            variant="outlined"
            name="submission[name]"
            onChange={(event) => setName(event.target.value)}
            required
          />
        </div>
        <div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '5px', paddingBottom: '5px' }}>
            <label htmlFor="prompt"><b>Prompt</b></label>
          </div>
          <TextField
            id="prompt"
            label={`Chat with ${selectedOption}. . .`}
            variant="outlined"
            name="submission[input_text]"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            required
          />
        </div>
        {showAudioPlayer ? (
            // Render your audio element here
            <audio controls src={audioSource} onEnded={handleAudioEnded} autoPlay></audio>
        ) : (
            <Button type="submit" variant="contained" style={{margin: "10px"}}>Chat</Button>
        )}
        </form>
        {loading && (
            <div style={{textAlign: "center"}}>
              <CircularProgress />
              <p>Loading...</p>
            </div>
        )}
      </div>
    );

}
